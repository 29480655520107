<template>
  <div id="user">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <div v-if="userEdited" class="vx-row w-full">
        <div class="vx-col w-1/3">
          <vs-input class="mt-2" :label="$t('first_name')" v-model="userEdited.first_name"/>
          <vs-input class="mt-2" :label="$t('last_name')" v-model="userEdited.last_name"/>
          <vs-input class="mt-2" :label="$t('user_lang')" v-model="userEdited.user_lang"/>
        </div>
        <div class="vx-col w-1/3">
          <vs-input class="mt-2" :label="$t('user_email')" v-model="userEdited.user_email" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('creationTime')" v-model="userEdited.sql_timestamp" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('user_id')" v-model="userEdited.user_id" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('ssoId')" v-model="userEdited.sso_id" :disabled="true"/>
        </div>
        <div class="vx-col w-1/3">
          <vs-button class="w-full" @click="dispatchUpdates" :disabled="!diffsToApiQuery">{{this.$t('Save')}}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import { getDiff } from 'recursive-diff'

export default {
  components: {
  },
  data () {
    return {
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      apiRoute: '/admin',
      loaded: true,
      user_email: null, 
      user: null, 
      userEdited: null
    }
  },
  methods: {
    dispatchUpdates () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('dispatchUserUpdatesText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          for (const [key, value] of Object.entries(this.diffsToApiQuery)) {
            params.set(key, JSON.stringify(value))
          }
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/user/updateUserSettings`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    }, 
    getEntityData () {
      const params = `user_email=${this.user_email}`
      const rimnat_api_call_url = `${this.api_server_baseurl}${this.apiRoute}/user/getUser?${params}`

      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          this.loaded = true
          const data = response.data
          this.user = data
          this.userEdited = JSON.parse(JSON.stringify(this.user))
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    }, 
    refreshAll () {
      this.loaded = false
      this.getEntityData()
    }
  },
 
  computed: {
    diffsToApiQuery () {
      if (this.diffs.length == 0) return 
      return { 
        context: {
          services_user_id: this.$store.state.AppActiveUser.user_id,
          services_user_hash: this.$store.state.AppActiveUser.user_hash
        }, 
        diffs: this.diffsToApi
      }
    },
    diffsToApi () {
      if (this.diffs.length == 0) return 
      const res = {
        user_email : this.user_email
      }
      const props = []
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          const prop = e.path[0]
          const val = e.val
          props.push({prop, val})
        }
      })
      res.updates = props
      return res
    },
    diffs () {
      return getDiff(this.user, this.userEdited, true)
    }
  },

  beforeMount () {
    this.user_email = this.$route.params.user_email
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.getEntityData()
  },

  watch: {
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
